export default {
  entity: 'frontend_2_3_1',
  create: false,
  fieldComponent: () => import('@/views/app/CreateComponent/ContactPersonForm.vue'),
  fields: [
    { key: 'contactperson_id', auto: true },
    {
      key: 'user_type',
      label: 'User type',
      type: 'custom-select',
      hideOnIndex: true,
      required: false,
      items: [
        { value: 1, label: 'Internal' },
        { value: 0, label: 'External' },
      ],
      send: false,
      visible: () => false,
      value: () => 0,
    },
    { key: 'contactperson_firstname' },
    { key: 'contactperson_lastname' },
    { key: 'city_name', hideOnForm: true },
    { key: 'contactdetails_phone', hideOnForm: true },
    { key: 'contactdetails_email', hideOnForm: true },
    { key: 'contactperson_department', hideOnIndex: true, rules: { required: false } },
    {
      key: 'contactperson_shortname',
      hideOnIndex: true,
      hideOnUpdate: true,
      disabled: true,
      value: entity => (entity.contactperson_firstname?.charAt(0) || '') + (entity.contactperson_lastname?.charAt(0) || ''),
    },
    { key: 'contactperson_function', rules: { required: false } },
    {
      key: 'user_id', type: 'list', list: 'user', listLabel: 'user_email', rules: { required: false },
    },
    {
      key: 'contactdetails_id',
      type: 'list',
      list: 'contactdetails',
      listLabel: 'contactdetails_email',
      hideOnIndex: true,
      alwaysNew: true,
      onlyForm: true,
    },
    {
      key: 'contactsalutation_id',
      type: 'list',
      list: 'contactsalutation',
      listLabel: 'contactsalutation_name',
      hideOnIndex: true,
      rules: { required: false },
    },
    {
      key: 'contacttitle_id',
      type: 'list',
      list: 'contacttitle',
      listLabel: 'contacttitle_name',
      hideOnIndex: true,
      rules: { required: false },
    },
    {
      key: 'address_id',
      type: 'list',
      list: 'address',
      listLabel: 'address_street',
      hideOnIndex: true,
      alwaysNew: true,
      onlyForm: true,
    },
  ],
  note: 'frontend_0_8_10',
}
