export const setLocalStorageValue = async (data, value) => {
  const {
    vm, board_id, column_id, priority_id,
  } = data
  vm.$set(vm.entity, 'ticket_priority_deadline_value', value)
  await vm.$store.dispatch('table/setListData', { entity: `ticket_priority_deadline_value-${board_id}-${column_id}-${priority_id}`, data: value })
}

export const getDeadlineColumnPriority = async ({
  board_id, column_id, priority_id, vm,
}) => {
  const storeData = vm.$store.getters['table/listCache'](`ticket_priority_deadline_value-${board_id}-${column_id}-${priority_id}`)

  if (typeof storeData === 'number') {
    return storeData
  }
  try {
    const response = await vm.$http.get('/boards/column-priority-deadlinevalue', {
      params: {
        board_id, column_id, priority_id,
      },
    })
    const data = response.data.priority_deadline_value
    await setLocalStorageValue({
      board_id, column_id, priority_id, vm,
    }, data)

    return data
  } catch (error) {
    console.log({ error })
  }
}

export const TIME_UNITS = [
  {
    label: 'Hour', value: 'hour', abr: 'h', toHourRatio: 1,
  },
  {
    label: 'Day', value: 'day', abr: 'd', toHourRatio: 24,
  },
  {
    label: 'Week', value: 'week', abr: 'w', toHourRatio: 168,
  },
  {
    label: 'Month', value: 'month', abr: 'm', toHourRatio: 730,
  },
  {
    label: 'Year', value: 'year', abr: 'y', toHourRatio: 8760,
  },
]

export const convertUnitTimeToHours = (value, unit) => {
  const __unit = TIME_UNITS.find(_unit => _unit.value === unit)

  if (!__unit) return value
  return value * __unit.toHourRatio
}
export const convertHourToUnitTime = (value, unit) => {
  const __unit = TIME_UNITS.find(_unit => _unit.value === unit)

  if (!__unit) return value
  return value / __unit.toHourRatio
}
